import React from "react";
import ImageCard from "../../../components/ImageCard";
import img44 from "../../../assets/images/44.webp";
import img45 from "../../../assets/images/45.webp";
import img46 from "../../../assets/images/46.webp";
import img47 from "../../../assets/images/47.webp";
import img48 from "../../../assets/images/48.webp";
import img49 from "../../../assets/images/49.webp";
import img50 from "../../../assets/images/50.webp";
import img51 from "../../../assets/images/51.webp";
import img53 from "../../../assets/images/53.webp";
import img56 from "../../../assets/images/56.webp";
import img57 from "../../../assets/images/57.webp";
import img58 from "../../../assets/images/58.webp";
import img80 from "../../../assets/images/80.webp";
import img81 from "../../../assets/images/81.webp";
import img82 from "../../../assets/images/82.webp";
import img83 from "../../../assets/images/83.webp";
import AndreMantovani from "../../../assets/images/personalities/AndreMantovani.jpeg";
import CaioPericinoto from "../../../assets/images/personalities/CaioPericinoto.jpeg";
import GabrielaLeite from "../../../assets/images/personalities/GabrielaLeite.jpeg";
import LennyLeone from "../../../assets/images/personalities/LennyLeone.png";
import LiviaCortinovis from "../../../assets/images/personalities/LiviaCortinovis.jpeg";
import LucileneCaetano from "../../../assets/images/personalities/LucileneCaetano.png";
import YasminLeite from "../../../assets/images/personalities/YasminLeite.jpeg";
import NatamiSantiago from "../../../assets/images/personalities/NatamiSantiago.jpeg";
import IzaMariana from "../../../assets/images/personalities/IzaMariana.jpg";
import LiviaNepomuceno from "../../../assets/images/personalities/LiviaNepomuceno.jpeg";
import MiltonNeves from "../../../assets/images/personalities/MiltonNeves.jpeg";

import {
  Container,
  Context,
  AreaText,
  AreaTitle,
  AreaDescription,
  Title,
  Description,
  AreaImages,
  Grid,
  AreaButton,
} from "./styles";
import Badge from "../../../components/Badge";
import Button from "../../../components/Button";

interface StarsProps {
  id: number;
  img: string;
  name: string;
  profession: string;
  sizeImage: "large" | "small";
  networks: {
    id: number;
    type: string;
    value: string;
  }[];
}

const OutStars = () => {
  const otherStars: StarsProps[] = [
    {
      id: 13,
      img: img44,
      name: "Ana Hickmann",
      profession: "Apresentadora e Empresária",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "http://instagram.com/ahickmann/",
        },
        {
          id: 2,
          type: "tiktok",
          value: "http://tiktok.com/@ahickmann",
        },
        {
          id: 3,
          type: "facebook",
          value: "https://www.facebook.com/anahickmannoficial",
        },
        {
          id: 4,
          type: "youtube",
          value: "http://youtube.com/@anahickmann",
        },
        {
          id: 5,
          type: "twitter",
          value: "http://twitter.com/ahickmann",
        },
      ],
    },
    {
      id: 14,
      img: img45,
      name: "Cleber Machado",
      profession: "Jornalista e Narrador Esportivo",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "http://instagram.com/oficial_clebermachado/",
        },
      ],
    },
    {
      name: "André Mantovani",
      profession: "Astrólogo e Tarólogo",
      sizeImage: "large",
      img: AndreMantovani,
      id: 40,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/andremantovanni/ ",
        },
        {
          id: 4,
          type: "youtube",
          value: "https://www.youtube.com/c/Andr%C3%A9Mantovannitv  ",
        },
      ],
    },
    {
      id: 15,
      img: img46,
      name: "Edu Guedes",
      profession: "Apresentador e Chef de Cozinha",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "http://instagram.com/eduguedesoficial/",
        },
        {
          id: 3,
          type: "facebook",
          value: "http://facebook.com/eduguedesreal",
        },
        {
          id: 4,
          type: "youtube",
          value: "https://www.youtube.com/c/TheChefcomEduGuedes",
        },
        {
          id: 5,
          type: "twitter",
          value: "http://twitter.com/eduguedesreal",
        },
      ],
    },
    {
      id: 51,
      img: MiltonNeves,
      name: "Milton Neves",
      profession: "Apresentador e Jornalista",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "http://instagram.com/miltonneves/",
        },
        {
          id: 3,
          type: "facebook",
          value: "http://facebook.com/miltonnevesoficial",
        },
        {
          id: 4,
          type: "youtube",
          value: "http://youtube.com/@Milton100MiMiMi",
        },
        {
          id: 5,
          type: "twitter",
          value: "http://twitter.com/miltonneves",
        },
      ],
    },
    {
      id: 2,
      img: img48,
      name: "Craque Neto",
      profession: "Apresentador",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "http://instagram.com/10neto/",
        },
        {
          id: 2,
          type: "tiktok",
          value: "http://tiktok.com/@10neto",
        },
        {
          id: 3,
          type: "facebook",
          value: "http://facebook.com/craqueneto",
        },
        {
          id: 4,
          type: "youtube",
          value: "http://youtube.com/@CraqueNeto10",
        },
        {
          id: 5,
          type: "twitter",
          value: "http://twitter.com/10neto",
        },
      ],
    },
    {
      name: "Caio Pericinoto",
      profession: "Influenciador Digital e Humorista",
      sizeImage: "large",
      img: CaioPericinoto,
      id: 42,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/caiopericinoto/",
        },
        {
          id: 2,
          type: "tiktok",
          value: "https://www.tiktok.com/@caiopericinoto",
        },
        {
          id: 3,
          type: "facebook",
          value: "https://www.facebook.com/caiopericinoto",
        },
        {
          id: 4,
          type: "youtube",
          value: "https://www.youtube.com/@caiopericinoto",
        },
      ],
    },

    {
      id: 10,
      img: img56,
      name: "Rudy Landucci",
      profession: "Ator e Humorista",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "http://instagram.com/rudylanducci/",
        },
        {
          id: 2,
          type: "tiktok",
          value: "http://tiktok.com/@rudylanducci",
        },
        {
          id: 3,
          type: "facebook",
          value: "https://pt-br.facebook.com/CanalDoRudy",
        },
        {
          id: 4,
          type: "youtube",
          value: "http://youtube.com/@CanaldoRudy",
        },
        {
          id: 5,
          type: "twitter",
          value: "http://twitter.com/rudylanducci",
        },
      ],
    },
    {
      id: 3,
      img: img49,
      name: "Fabiana Oliveira",
      profession: "Apresentadora e Repórter",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/afabianaoliveira/",
        },
        {
          id: 5,
          type: "twitter",
          value: "https://twitter.com/fabi_oliveiratv",
        },
      ],
    },
    {
      id: 7,
      img: img53,
      name: "Mario Marra",
      sizeImage: "small",
      profession: "Jornalista e Comentarista Esportivo",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/marramario/",
        },
        {
          id: 5,
          type: "twitter",
          value: "http://twitter.com/mariomarra",
        },
      ],
    },
    {
      id: 50,
      img: LiviaNepomuceno,
      name: "Lívia Nepomuceno",
      sizeImage: "small",
      profession: "Jornalista e Apresentadora da TV Bandeirantes",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/livianepomuceno/",
        },
        {
          id: 5,
          type: "twitter",
          value: "http://twitter.com/livianepomuceno",
        },
      ],
    },
    {
      id: 18,
      img: img82,
      name: "Carol Narizinho",
      profession: "Criadora de conteúdo digital",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/carolnarizinho/",
        },
        {
          id: 2,
          type: "tiktok",
          value: "https://www.tiktok.com/@ccarolnarizinho",
        },
        {
          id: 3,
          type: "facebook",
          value: "https://www.facebook.com/ccarolnarizinho",
        },
      ],
    },
    {
      name: "Lucilene Caetano",
      profession: "Jornalista e Apresentadora",
      sizeImage: "large",
      img: LucileneCaetano,
      id: 47,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/lucilenecaetanooficial/",
        },
      ],
    },
    {
      name: "Iza Mariana",
      profession: "Empresária e Influenciadora",
      sizeImage: "large",
      img: IzaMariana,
      id: 44,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/iza.marianaa/",
        },
        {
          id: 4,
          type: "youtube",
          value: "https://www.youtube.com/c/natizachannel",
        },
      ],
    },
    {
      name: "Natami Santiago",
      profession: "Empresária e Influenciadora",
      sizeImage: "large",
      img: NatamiSantiago,
      id: 48,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/natami.santiago/",
        },
        {
          id: 4,
          type: "youtube",
          value: "https://www.youtube.com/c/natizachannel",
        },
      ],
    },
    {
      id: 1,
      img: img47,
      name: "Celso Kamura",
      profession: "Beauty Artist",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "http://instagram.com/celsokamuraoficial/",
        },
        {
          id: 3,
          type: "facebook",
          value: "http://facebook.com/celsokamuramoema",
        },
        {
          id: 4,
          type: "youtube",
          value: "http://youtube.com/@CKamuraOficial",
        },
      ],
    },
    {
      name: "Gabriela Leite",
      profession: "Reporter",
      sizeImage: "large",
      img: GabrielaLeite,
      id: 43,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/gabrielaleiteg/",
        },
      ],
    },
    {
      name: "Yasmin Leite",
      profession: "Reporter",
      sizeImage: "large",
      img: YasminLeite,
      id: 49,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/leiteyasmin/",
        },
      ],
    },
    {
      name: "Lenny Leone",
      profession: "Jornalista e Apresentadora",
      sizeImage: "large",
      img: LennyLeone,
      id: 45,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/lennyleone/",
        },
      ],
    },
    /*{
      name:"Livia Cortinóvis",
      profession: "Empresária e Apresentadora",
      sizeImage: "large",
      img: LiviaCortinovis,
      id:46,
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/liviacortinoviss/",
        },
      ],
    },*/
    {
      id: 4,
      img: img50,
      name: "Fernando Fernandes",
      profession: "Apresentador",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/ffernandes/",
        },
      ],
    },
    {
      id: 12,
      img: img58,
      name: "Ulisses Costa",
      profession: "Apresentador",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/ulissescostanarrador/",
        },
      ],
    },
    {
      id: 5,
      img: img51,
      name: "Gabi Matis",
      profession: "Modelo",
      sizeImage: "small",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/gabimatiis/",
        },
      ],
    },
    {
      id: 17,
      img: img80,
      name: "Mariana Baxur",
      profession: "Modelo",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/baxur/",
        },
      ],
    },
    {
      id: 16,
      img: img81,
      name: "Robson Jassa",
      profession: "Beauty Artist",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/robsonjassa/",
        },
      ],
    },
    {
      id: 11,
      img: img57,
      name: "Shemuel Shoel",
      profession: "Chef de Cozinha",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/shemuelshoel/",
        },
      ],
    },
    {
      id: 83,
      img: img83,
      name: "Alexandre Porpetone",
      profession: "Ator e Humorista",
      sizeImage: "large",
      networks: [
        {
          id: 1,
          type: "instagram",
          value: "https://www.instagram.com/alexandrePorpetone/",
        },
        {
          id: 2,
          type: "facebook",
          value: "https://www.facebook.com/Porpetone",
        },
        {
          id: 3,
          type: "youtube",
          value: "https://www.youtube.com/@AlexandrePorpetone1",
        },
        {
          id: 4,
          type: "tiktok",
          value: "https://www.tiktok.com/@alexandreporpetone",
        },
        {
          id: 5,
          type: "twitter",
          value: "https://x.com/Porpetonehumor",
        },
      ],
    },
  ];

  return (
    <Container>
      <Context>
        <AreaImages>
          {/* <Row>
            {initialStars.map(({ id, img, name, profession, networks }) => (
              <ImageCard
                key={id}
                img={img}
                description={name}
                size="large"
                profession={profession}
                networks={networks}
              />
            ))}
          </Row> */}
          <Grid>
            {otherStars.map(
              ({ id, img, name, networks, profession, sizeImage }) => (
                <ImageCard
                  key={id}
                  img={img}
                  description={name}
                  size="small"
                  sizeImage={sizeImage}
                  networks={networks}
                  profession={profession}
                />
              )
            )}
          </Grid>
          <AreaButton>
            <a
              href="https://cadastro.plataformaoceano.com.br/register/contratante"
              target="_blank"
            >
              <Button text="Comece aqui" width="24rem" />
            </a>
          </AreaButton>
        </AreaImages>
      </Context>
    </Container>
  );
};

export default OutStars;
